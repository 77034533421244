import {
  Card,
  CardContent,
  CardHeader,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  H1,
  Icon,
  Separator,
  Tag,
} from '@hapstack/ui'
import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'

export const RouteErrorCard = () => {
  const error = useRouteError()
  const isRouteError = isRouteErrorResponse(error)
  captureRemixErrorBoundaryError(error)

  return (
    <Card>
      <CardHeader>
        <div>
          <Tag
            color="grey"
            size="lg"
          >
            {isRouteError ? error.status : 500} error
          </Tag>
          <H1>Uh oh.</H1>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <p>
            Error message:{' '}
            <span className="italic">
              {isRouteError
                ? error.statusText
                : error instanceof Error
                  ? error.message
                  : 'An unknown error occurred.'}
            </span>
          </p>

          {isRouteError && error.data ? (
            <>
              <Separator />
              <Collapsible>
                <CollapsibleTrigger className="flex items-center gap-0.5 text-xs text-muted-foreground">
                  More details{' '}
                  <Icon
                    name="chevron-down"
                    className="mt-0.5"
                  />
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <pre className="mt-2 whitespace-pre-wrap rounded border border-border bg-gray-50 p-4 text-xs text-muted-foreground">
                    {JSON.stringify(error.data)}
                  </pre>
                </CollapsibleContent>
              </Collapsible>
            </>
          ) : null}
        </div>
      </CardContent>
    </Card>
  )
}
